<template>
<div>
    <div class="study-list" v-for="(item,index) in studyList" :key="index" @click="studyLink(item)">
        <img :src=" item.cover_picture " class="list-img"/>
        <div class="study-info">
          <div class="list-tit white-space">{{ item.name }}</div>
          <div class="list-intro white-space" v-if="item.description">{{ item.description }}</div>
          <div class="list-time vertical-center">
            <div class="list-time-status" v-if="item.stage_status == 1">报名中</div>
            <div class="list-time-status studying" v-if="item.stage_status == 2">学习中</div>
            <div class="list-time-status end" v-if="item.stage_status == 0">已截止</div>
            <img  class="list-time-img" src="~assets/images/user/time.png" />
            <span v-if="item.stage_status != 2">{{item.apply_start_time ? item.apply_start_time.slice(5,10) : ''}}~{{item.apply_end_time ? item.apply_end_time.slice(5,10) : ''}}</span>
            <span v-if="item.stage_status == 2">{{item.start_at ? item.start_at.slice(5,10) : ''}}~{{item.end_at ? item.end_at.slice(5,10) : ''}}</span>
          </div>
        </div>
<!--        <div class="list-btn theme-color" :class="{ 'forbid-bg' : item.user_status == 1 }" v-if=" item.user_status == 1 && item.can_join == 1">-->
<!--            {{ item.user_status == 0 ? '报名' : '已报名' }}-->
<!--        </div>-->
<!--        <div class="list-btn theme-color" v-else :class="{ 'forbid-bg' : item.can_join == 0}">报名</div>-->
      <div class="list-btn theme-color" v-if="item.can_join == 1 && item.user_status == 0">报名</div>
      <div class="list-btn theme-color forbid-bg" v-else-if="item.user_status == 1">已报名</div>
      <div class="list-btn theme-color forbid-bg" v-else >已截止</div>
    </div>
</div>

</template>

<script>
export default {
  name: "study",
  props: ['study'],
  data() {
    return {
      studyList: []
    }
  },
  created() {
    if (this.study.length) {
      this.studyList = this.study
    }
  },
  methods: {
    studyLink(data) {
      this.$router.push({path: `/study/show/${data.id}`})
    }
  },
  watch: {
    study(val, oldval) {
      this.studyList = val
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.study-list {
  display: flex;
  position: relative;
  margin-bottom: 22/$r;
  margin-left: 30/$r;
  margin: 0 30/$r 22/$r 30/$r;
  padding-bottom: 22/$r;
  border-bottom: 1px solid #EEEEEE;

  .list-img {
    width: 120/$r;
    height: 120/$r;
    border-radius: 4/$r;
    display: block;
    margin-right: 24/$r;
  }

  .study-info {
    /* padding-bottom: 20/$r; */
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .list-tit {
      max-width: 380/$r;
      font-size: 28/$r;
      font-weight: bold;
      color: #262626;
      line-height: 36/$r;
    }

    .list-intro {
      font-size: 24/$r;
      color: #999999;
      line-height: 30/$r;
      max-width: 400/$r;
    }

    .list-time {
      font-size: 24/$r;
      font-family: PingFang SC;
      font-weight: bold;
      color: #999999;

      .list-time-status {
        display: inline-block;
        font-size: 24/$r;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        background: #ED3D4B;
        border-radius: 15/$r 15/$r 15/$r 0/$r;
        padding: 4/$r 8/$r;
        line-height: 22/$r;
      }
      .studying{
        background: #57AEFF;
      }
      .end{
        background: #BFBFBF;
      }

      .list-time-img {
        width: 28/$r;
        height: 28/$r;
        margin: 0 10/$r;
      }
    }
  }

  .list-btn {
    width: 120/$r;
    height: 50/$r;

    /* background: linear-gradient(0deg, #F28E26 0%, #FD644F 100%); */
    background: #FF6B29;
    border-radius: 27/$r;
    font-size: 24/$r;
    color: #FFFFFF;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    display: block;
    padding: 12/$r 0;
    box-sizing: border-box;
  }
}
.study-list:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.forbid-bg {
  border: 1px solid #BFBFBF;
}

</style>