<template>
  <div>
    <!-- 用户信息开始 -->
    <div class="user-box">
      <div class="user-contre">
        <div class="user-detail">
          <div class="detail-top vertical-center">
            <div class="avatar-box">
              <van-image fit="cover" v-if="userInfo && userInfo.avatar"  class="avatar align-center" :src="userInfo.avatar && userInfo.avatar ? userInfo.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'"></van-image>
              <van-image fit="cover" v-else class="avatar align-center" :src=" $confStatic.avatar "></van-image>
              <img src="~assets/images/user/vip-icon.png" v-if="userInfo && userInfo.is_vip == 1" class="vip-icon" alt="">
               <div class="rank-num align-center">
                  <div>
                    Lv{{ userInfo ? userInfo.level : '0' }}
                  </div>
                </div>
            </div>
            <div class="detail-top-r">
              <div class="user-info-box vertical-center">
                <div class="name white-space">{{ userInfo && userInfo.nickname ? userInfo.nickname : '酸谈社群' }}</div>

                <img class="icon" v-if="userInfo && userInfo.is_team_master && userInfo.is_team_master == 1"
                     src="~assets/images/index/captain-icon.png"/>
              </div>
              <div class="rank-serial-box vertical-center">
                <div class="serial-number" v-if="userInfo && userInfo.number">编号:{{userInfo.number}}</div>
                <img src="~assets/images/public/captain-s.png" v-if="userInfo && userInfo.is_group_master"  class="captain-s" />
                <img :src="userInfo.user_label_picture" v-if="userInfo && userInfo.user_label_picture" class="labelimg">
                <img src="~assets/images/public/36ce.png" v-else class="labelimg">
              </div>


            </div>

            <!--战队状态-->
            <div class="team-status" @click="goTeam" v-if="userInfo">
<!--              <div class="join-btn" v-if="userInfo.status_team.current_status == 5 || (userInfo.status_team.current_status == -1 && userInfo.status_team.operate_status == 4)">+加入战队</div>-->
              <div class="team-info" v-if="userInfo && userInfo.team_name">
                  <div class="team-name white-space">{{ userInfo ? userInfo.team_name : '' }}</div>
                <div class="team-rank">TOP{{ userInfo ? userInfo.team_rank : '' }}</div>
              </div>
              <!-- 加入战队 -->
            </div>
          </div>

          <div class="detail-bot space-between">
            <div class="detail-bot-list vertical-center" @click="historyLink('amount_credit')">
              <div class="list-num"><span>积分</span>{{ userInfo ?  $handle.tranNumber(userInfo.credit ? userInfo.credit : 0,3) : 0 }}</div>
            </div>
            <div class="detail-bot-list vertical-center" @click="historyLink('experience')">
              <div class="list-num"><span>经验值</span>{{ userInfo ? $handle.tranNumber(userInfo.experience ? userInfo.experience : 0,3) : 0 }}</div>
            </div>
            <div class="detail-bot-list vertical-center" @click="rankingLink(userInfo && userInfo.team_id ? userInfo.team_id : '')">
              <div class="list-num"><span>用户排名</span>{{ userInfo ? $handle.tranNumber(userInfo.rank ? userInfo.rank : 0,3) : 0 }}</div>
            </div>
          </div>
        </div>
        <!-- <van-image class="user-bg-img" fit="cover" :src="require('../assets/images/index/user-contre-bg.png')"/> -->
      </div>
      <van-image class="user-bg-img" fit="cover" :src="require('../assets/images/index/user-bg-img.png')"/>
    </div>
    <!-- 用户信息结束 -->

    <!--广告位开始-->
    <div class="advertising-wrap"  v-if="slidesData != null && slidesData.length > 0">
      <van-swipe class="advertising-swipe" :autoplay="3000" :show-indicators="false"  ref="swipe">
        <van-swipe-item class="advertising-swipe-item" v-for="(item,index) in slidesData" :key="index"
                        @click="adsLink(item)">
          <img :src="item.carousel" class="advertising-img" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!--广告位结束-->

    <!--消息提示开始-->
    <div class="notice-wrap vertical-center" v-show="Notices != null && Notices.length > 0">
      <img class="notice-icon" src="~assets/images/index/hint.png" />
      <van-swipe class="notice-item" vertical :show-indicators="false" :autoplay="3000">
        <van-swipe-item v-for="(item,index) in Notices" :key="index" @click="noticesLink(item)">
          <div class="notice-list white-space">
            {{ item.format_time }} {{ item.title }}
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!--消息提示结束-->

    <!--分割线开始-->
    <div class="line-bac" v-if="taskData != null && taskData.length > 0"></div>
    <!--分割线结束-->

    <!--今日任务开始-->
    <div class="module-wrap" v-if="taskData != null && taskData.length > 0">
      <div class="module-head vertical-center space-between">
        <div class="module-title">{{ (userInfo && userInfo.is_fresher == 1) ? '新手任务' : '今日任务' }}</div>
        <router-link tag="div" to="/task?type=today" class="module-r vertical-center">
          <div class="">查看更多</div>
          <img class="more-right" src="~assets/images/index/more-right.png"/>
        </router-link>
      </div>
      <div class="module-cont task-item">
        <task :task="taskData" :package_id="package_id"></task>
      </div>
    </div>
    <!--今日任务结束-->

    <!--分割线开始-->
    <div class="line-bac" v-if=" studyData.length "></div>
    <!--分割线结束-->

    <!--学习小组开始-->
    <div class="module-wrap study-wrap" v-if="studyData.length">
      <div class="module-head vertical-center space-between">
        <div class="module-title">学习小组</div>
        <router-link tag="div" to="/study" class="module-r vertical-center">
          <div class="">查看更多</div>
          <img class="more-right" src="~assets/images/index/more-right.png"/>
        </router-link>
      </div>
      <div class="module-cont study-item">
        <study :study="studyData"></study>
      </div>
    </div>
    <!--学习小组结束-->

    <!--分割线开始-->
    <div class="line-bac"></div>
    <!--分割线结束-->


 <!-- 悬赏帮助开始 -->
    <div class="module-wrap">
        <div class="module-head vertical-center space-between">
            <div class="module-title">悬赏问答</div>
            <div class="more-right-con module-r vertical-center"  @click="communityLink('0')">
                 <div class="">查看更多</div>
                <img class="more-right"  src="~assets/images/index/more-right.png" />
            </div>
        </div>
        <div class="module-cont">
            <bounty :bountyData="bountyList"></bounty>
        </div>
    </div>
    <!-- 悬赏帮助结束 -->


    <!--精彩推荐开始-->
    <!-- <div class="module-wrap wonderful-wrap" v-if="recommends.length">
      <div class="module-head vertical-center space-between">
        <div class="module-title">精彩推荐</div>
      </div>
      <div class="module-cont">
        <ul class="wonderful-item">
          <li class="wonderful-item-li" v-for="(item,index) in recommends" :key="index" @click="recommendsLink(item)">
            <img :src="item.thumb_url" class="wonderful-list"/>
          </li>
        </ul>
      </div>
    </div> -->
    <!--精彩推荐结束-->

      <!--分割线开始-->
      <div class="line-bac" ></div>
      <!--分割线结束-->


<!--跳蚤市场开始-->
    <div class="module-wrap" >
        <div class="module-head vertical-center space-between">
            <div class="module-title">跳蚤市场</div>
            <div class="more-right-con module-r vertical-center"  @click="communityLink('1')">
              <div class="">查看更多</div>
                <img class="more-right"  src="~assets/images/index/more-right.png" />
            </div>
        </div>
        <div class="module-cont">
           <NoData v-if="fleaList == '' || fleaList.length == 0" bottomBg="true"></NoData>
            <div  class="market-swipe" >
                <!-- <flea fleaData="{{fleaList}}"></flea> -->
                <div @click="gomarket(item.id)"  class="market-swipe-item"  v-for="(item,index) in fleaList"  :key="index">
                    <img  class="market-img" :src="item.picture[0]" />
                    <div class="market-title white-space-2">
                        <div class="market-title-mail"  v-if="item.is_free_shipping">包邮</div>
                        {{item.title}}
                    </div>
                    <div class="market-integral">
                        {{item.credit}}积分
                        <span class="want">
                            {{$handle.tranNumber( item.collect_num,3)}}个想要
                        </span>
                    </div>
                    <user-info :wechat="item.user_profile && item.user_profile.wechat" class="white-space" :labelimg="item.user_profile && item.user_profile.user_label && item.user_profile.user_label.picture" :vipimg="item.user_profile && item.user_profile.user && item.user_profile.user.level"></user-info>
                </div>
            </div>
        </div>
    </div>
    <!--跳蚤市场结束-->


    <!--分割线开始-->
    <div class="line-bac" ></div>
    <!--分割线结束-->

    <!-- 资源互助开始 -->
    <div class="module-wrap">
      <div class="module-head vertical-center space-between">
        <div class="module-title">资源互助</div>
        <div class="more-right-con module-r vertical-center"  @click="communityLink('0')">
          <div class="">查看更多</div>
          <img class="more-right"  src="~assets/images/index/more-right.png" />
        </div>
      </div>
      <div class="module-cont">
        <resources :electronData="eleList"></resources>
      </div>
    </div>
    <!-- 资源互助结束 -->


    <!--商品推荐开始-->
    <!-- <div class="module-wrap commodity-wrap" v-if="goodsData.length">
      <div class="module-head vertical-center space-between">
        <div class="module-title">商品推荐</div>
        <router-link tag="div" to="/malls" class="module-r vertical-center">
          <div class="">更多</div>
          <img class="more-right" src="~assets/images/index/more-right.png"/>
        </router-link>
      </div>
      <div class="module-cont">
        <mall-list :list="goodsData" :module="3"></mall-list>
      </div>
    </div> -->
    <!--商品推荐结束-->

    <!--选择标签样式开始-->
    <van-popup v-model:show="label_popup_static" position="bottom" :round="true" :close-on-click-overlay="false" z-index="9999">
      <div class="label-wrap">
        <div class="label-tit">请选择标签</div>
        <div class="label-cont">
          <div class="label-module-tit">兴趣</div>
          <div class="label-module-item">
            <van-row gutter="16">
              <van-col span="6" v-for="(item,index) in tags.interest" :key="index" @click="choseInterest(item,index)" >
                <div class="label-list white-space" :class="{ 'label-list-act' : item.interest_status }">
                  {{ item.name }}
                </div>
              </van-col>
            </van-row>
          </div>
          <div class="label-module-tit target-tit">目标</div>
          <div class="label-module-item">
            <van-row gutter="16">
              <van-col span="6" v-for="(item,index) in tags.target" :key="index" @click="choseTarget(item,index)">
                <div class="label-list white-space" :class="{'label-list-act': target_num == index }">{{ item.name }}
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>

      <div class="bot-height safety-height"></div>
      <div class="submit-btn-box">
        <div class="submit-btn theme-bg" v-bind:class="{ 'forbid-bg' : (interest.length == 0 || target.length == 0) }"
             @click="tagsFun">{{ interest.length > 0 && target.length > 0 ? '确定' : '先选择兴趣和目标吧' }}
        </div>
        <div class="safety-height"></div>
      </div>
    </van-popup>
    <!--选择标签样式结束-->
  </div>
</template>

<script>
import mallList from "./mallList"
import study from "./study"
import task from "./task"
import UserInfo from "components/userInfo";
import bounty from "./bounty"
import resources from "./resources"
import NoData from "components/NoData";
const urlEncode = require('urlencode');

export default {
  name: 'Home',
  data() {
    return {
      tags: {}, // 获取标签和目标
      label_popup_static: false, // 标签显示状态
      login_state: false, // 登录状态
      target_num: -1, // 目标索引
      interest_num: -1, // 兴趣索引
      login_tell: false, //手机号授权状态
      interest: [],  // 兴趣
      target: [],     //目标
      slidesData: [],    // 广告数据
      Notices: [],    // 通知数据
      goodsData: [],  // 推荐数据
      taskData: [],   // 任务列表
      package_id: '', // 任务报id
      studyData: [],   // 学习小组列表
      recommends: [],    // 精彩推荐
      target_status: true,
      bountyList:[],
      fleaList:[],
      eleList:[]
    }
  },
  created() {
    /*判断是否登录显示
    * */
    if (!this.$cache.getIsLogin()) {
      this.label_popup_static = false
    } else {
      if (this.userInfo && !this.userInfo.has_chose && this.userInfo.is_join == 1) {
        this.label_popup_static = true;
      } else {
        if (this.userInfo != null) {
          // 重新展示首页数据
          if(this.userInfo.is_join == 1){
            this.getTask();
          }
          this.getStudy();
       
          // this.getRecommends();
        }
      }
    }
       this.getbountyList()
        this.getfleaList()
    this.geteleList()
    // 获取标签
    this.getTags();
    // 获取广告
    this.getAds();
    // 获取通知的
    this.getNotices();
    // 商品推荐
    // this.getGoods();
    
  },
  methods: {
     // 悬赏问答
        getbountyList(){
            let _this = this;
            let url = this.$api.Bounty + '?pageSize=1&page=1' 
            this.$http.get(url, false).then(res => {
              if(res.data.success){
                if(res.data.data.length){
                  _this.bountyList = res.data.data
                  
                }
              }
            })
        },
        //跳蚤市场列表
    getfleaList(){
        let _this = this;
        let url = this.$api.Flea + '?pageSize=5&page=1&category_id=0'
        this.$http.get(url, false).then(res => {
          if(res.data.success){
            if(res.data.data.length){
              _this.fleaList = res.data.data
            }
          }
        })
      },
    // 悬赏问答
    geteleList(){
      let _this = this;
      let url = this.$api.electron + '?pageSize=1&page=1'
      this.$http.get(url, false).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            _this.eleList = res.data.data

          }
        }
      })
    },
       //社区跳转
        communityLink(act) {
          this.$store.commit('M_ACT',act)
          this.$router.push({path: `/community`})
        },
        //跳转跳蚤详情
gomarket(id){
     this.$router.push({path: '/community/market-show',query: {id: id}})
  },
    // 获取标签
    getTags() {
      let _this = this;
      this.$http.get(this.$api.Tags, false).then(res => {
        if (res.data.success) {
          _this.tags = res.data.data;

          // 路由判断
          if (_this.$route.query.fullPath) {
            let tagData = _this.$handle.decrypt(urlEncode.decode(_this.$route.query.fullPath));
            if (tagData.interest) {
              _this.interest = tagData.interest;
              _this.target = tagData.target;
              _this.interest_num = tagData.interest_num;
              _this.target_num = tagData.target_num;
              // 默认选中所选的
              for (let i = 0; i < _this.tags.interest.length; i++) {
                for (let j = 0; j < tagData.interest.length; j++) {
                  if (_this.tags.interest[i].id == tagData.interest[j]) {
                    _this.tags.interest[i].interest_status = true
                  }
                }
              }
            }
          }
        }
      })
    },
    // 兴趣选择
    choseInterest(item, index) {
      let id_arr = [];
      this.tags.interest[index].interest_status = !this.tags.interest[index].interest_status;
      for (let i = 0; i < this.tags.interest.length; i++) {
        if (this.tags.interest[i].interest_status) {
          id_arr.push(this.tags.interest[i].id)
        }
      }
      this.interest = id_arr
    },
    // 提交目标选择
    tagsFun() {
      if (this.interest.length == 0 || this.target.length == 0) {
        return false
      }
      if(this.userInfo && this.userInfo.status_team && this.userInfo.status_team.operate_status == 3){
        this.tagsRequest()
      }
      /*let params = this.$handle.encryption({
        target_num: this.target_num,
        interest_num: this.interest_num,
        interest: this.interest,
        target: this.target
      });
      if (this.$cache.getIsLogin()) {

      } else {
        this.$weChat.wechat(params)
      }*/
    },
    tagsRequest() {
      let _this = this;
      if(!this.target_status){
        return false
      }
      this.target_status = false;
      let data = {
        interest: this.interest.toString(),
        target: this.target.toString()
      }
      this.$http.post(this.$api.UserTags, data, true).then(res => {
        if (res.data.success) {
          this.$toast('提交成功');
          this.label_popup_static = false;
          this.$user.getUserInfo();
          setTimeout(()=>{
              // 重新展示首页数据
              _this.getTask();
              _this.getStudy();
              _this.getRecommends();
          },1000)
        }else {
          this.label_popup_static = false;
          this.$toast(res.data.message);
        }
        this.target_status = true;
      })
    },
    // 目标选择
    choseTarget(item, index) {
      this.target = [];
      this.target_num = index;
      this.target.push(item.id)
    },
    // 获取广告
    getAds() {
      this.$http.get(this.$api.Slides + '?limit=4').then(res => {
        if (res.data.success) {
          this.slidesData = res.data.data;
          this.$refs.swipe.resize();
        }
      }).catch((e) => {
      })
    },
    // 获取通知的
    getNotices() {
      this.$http.get(this.$api.Notices).then(res => {
        if (res.data.success) {
          this.Notices = res.data.data;
        }
      }).catch((e) => {
      })
    },
    // 商品推荐
    // getGoods() {
    //   let url = this.$api.Goods + '?f=' + this.$handle.encryption({is_recommend: true}) + '&limit=3';
    //   this.$http.get(url).then(res => {
    //     if (res.data.success) {
    //       this.goodsData = res.data.data;
    //     }
    //   })
    // },
    // 获取任务列表
    getTask() {
      let _this= this;
      let url = this.$api.Tasks + '?getTime=' + new Date().getTime();
      if (this.userInfo && this.userInfo.is_fresher == 0) {
        url = this.$api.Tasks + '?type=today&getTime=' + new Date().getTime();
      }
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          _this.package_id = res.data.data.package_id
          if(res.data.data.data){
            _this.taskData = _this.$util.taskSort(res.data.data.data).slice(0,3);
          }
        }
      })
    },
    // 获取学习小组
    getStudy() {
      let url = this.$api.Groups + '?limit=4' + '&is_apply=1';
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.studyData = res.data.data
        }
      })
    },
    // 获取精彩推荐
    // getRecommends() {
    //   let url = this.$api.Recommends + '?limit=4';
    //   this.$http.get(url, true).then(res => {
    //     if (res.data.success) {
    //       this.recommends = res.data.data
    //     }
    //   })
    // },
    recommendsLink(data) {
      if(data.link_url !== '' && data.link_url !== null){
        window.location.href = data.link_url
      }else {
        this.$router.push({path: `/user/recommend/${data.id}`})

      }
    },
    // 广告点击
    adsLink(data) {
      window.location.href = data.url;
    },
    //  通知信息路由
    noticesLink(data) {
      this.$router.push({path: `/user/message/${data.id}`})
    },
    //  加入战队
    goTeam() {
      if(!this.$cache.getIsLogin()){
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      this.$router.push({path: '/user/team'})
    },
    //  跳转历史记录
    historyLink(type) {
      if(!this.$cache.getIsLogin()){
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      this.$router.push({path: '/user/history',query:{type:type}})
    },
    //  个人排行榜
    rankingLink(id) {
      if(!this.$cache.getIsLogin()){
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      if (id === 0){
        this.$router.push({path: '/ranking'})
      }else {
        this.$router.push({path: '/ranking/'+id})
      }
    },
  },
  components: {
    mallList,
    study,
    task,
    bounty,
    UserInfo,
    resources,
    NoData
  },
  watch: {
    userInfo: {
      handle:function (val, oldVal){
        if(!val.has_chose && val.is_join == 1) {
          this.label_popup_static = true;
        } else {
          this.label_popup_static = false;
        }
        if(val.is_join == 1){
          this.getTask();
        }
        this.getStudy();
        this.getRecommends();
        return val;
      },
      deep: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/home/home";
</style>
<style lang="scss">
$r: 750/16/1rem;
.module-wrap .reward-item{
  padding: 0;
}
.market-swipe{
.userinfo .userinfo-name{
    font-size: 22/$r!important;
    color: #999999!important;
    max-width: 95/$r;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }  
}
  
</style>
