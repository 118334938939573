<template>
  <div>
    <!--  首页  -->
    <home-page></home-page>
  </div>
</template>

<script>
import HomePage from "../../components/HomePage";
export default {
  name: "Home",
  data(){
    return{

    }
  },
  created() {

  },
  components: {
    HomePage
  }
}
</script>

<style scoped lang="scss">

</style>