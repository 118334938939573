<template>
  <div>
    <div v-if="task">
      <div class="task-list vertical-center space-between" v-for="(item,index) in taskData" :key="index"
           @click="taskLink(item)">
        <div class="vertical-center space-between">
          <img :src="item.cover_picture ? item.cover_picture : $confStatic.taskImg" class="list-img"/>
          <div class="list-info">
            <div class="list-tit white-space">{{ item.name }}</div>
            <div class="list-hint vertical-center">
              <img src="~assets/images/index/task.png" class="num-icon"/>
              <div class="list-num theme-color">经验值+{{ $handle.tranNumber(item.experience ? item.experience : 0,3) }}</div>
            </div>
          </div>
        </div>
        <div class="list-btn forbid-bg" v-if="item.code == 'limited' && !item.can_do && item.status != 1 ">已过期</div>
        <div class="list-btn forbid-bg" v-else-if=" item.status == 1 ">已完成</div>
        <div class="list-btn audit-status-btn" v-else-if=" item.status == 0 ">待审核</div>
        <div class="list-btn audit-status-btn" v-else-if=" item.status == -1 ">已驳回</div>
        <div class="list-btn gradual-bac" v-else-if=" item.status == 5 ">去完成</div>
      </div>

    </div>
    <!--转队不能做任务提示开始-->
    <van-overlay :show="isReject">
      <div class="overlay-confirmation-audit-con align-center">
        <div class="overlay-confirmation-audit">
          <div class="overlay-confirmation-head align-center">
            <div class="overlay-confirmation-text">提示</div>
          </div>
          <div class="overlay-confirmation-body vertical-center">
            <div class="overlay-confirmation-tip-text">由于本周发生退/换队行为，所以无法再次完成本周其他任务，敬请见谅~</div>
            <div class="overlay-btn">
              <div class="cancel" @click="isReject = false">取消</div>
              <div class="confirm" @click="isReject = false">确定</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--转队不能做任务提示开始结束-->
  </div>


</template>

<script>
export default {
  name: "task",
  props: ['task','package_id','active'],
  data() {
    return {
      taskData: [],
      isReject: false,
    }
  },
  created() {
    if (this.task.length) {
      this.taskData = this.task
    }
  },
  methods: {
    // 路由跳转
    taskLink(data) {
      if (this.userInfo.can_do || data.code == 'limited'){
        // 判断
        if(data.code == 'limited' && !data.can_do && data.status != 1 ){
          return false
        }
        if(data.type == 1 && (!data.app_id && data.url)){
          this.$router.push({path: data.url})
        }else if(data.type == 4){
          this.$router.push({path: '/user/team'})
        }else{
          window.location.href = window.location.origin + `/task/show/${this.package_id}/${data.id}`
          // this.$router.push({path: `/task/show/${this.package_id}/${data.id}`})
        }
      }else {
        this.isReject = true
      }
    }

  },
  watch: {
    task(val, oldValue) {
      this.taskData = val
    },
    userInfo(val, oldVal){
      return val;
    }
  },
  computed:{
    userInfo(){
      return this.$store.getters.userInfo;
    }
  },
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.task-list {
  position: relative;
  padding-bottom: 12/$r;
  margin-bottom: 30/$r;
  border-bottom: 1px solid #E6E6E6;

  .list-img {
    width: 80/$r;
    height: 80/$r;
    background: #EEEEEE;
    border-radius: 50%;
    display: block;
  }

  .list-info {
    margin-left: 12/$r;

    .list-tit {
      max-width: 420/$r;
      font-size: 28/$r;
      font-weight: bold;
      color: #262626;
      line-height: 36/$r;
      margin-bottom: 8/$r;
    }

    .list-hint {
      .num-icon {
        width: 26/$r;
        height: 26/$r;
        display: block;
        margin-right: 6/$r;
      }

      .list-num {
        font-size: 22/$r;
        font-weight: bold;
      }
    }
  }

  .list-btn {
    width: 120/$r;
    height: 50/$r;
    background: linear-gradient(0deg, #F28E26 0%, #FD644F 100%);
    border-radius: 25/$r;
    font-size: 24/$r;
    color: #FFFFFF;
    font-weight: 500;
    text-align: center;
    line-height: 50/$r;
    display: block;
  }
}

.task-list:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
$r: 750/16/1rem;
//转队不能做任务提示开始
:deep(.van-overlay){
  z-index: 100;
}
.overlay-confirmation-audit-con{
  height: 100%;
  .overlay-confirmation-audit{
    width: 600/$r;
    background: #FFFFFF;
    border-radius: 20/$r;
    .overlay-confirmation-head{
      width: 600/$r;
      height: 127/$r;
      box-sizing:border-box;
      background: #FF9E73;
      border-radius: 20/$r 20/$r 0/$r 0/$r;
      .overlay-confirmation-text{
        font-size: 32/$r;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    .overlay-confirmation-body{
      flex-direction: column;
      .overlay-confirmation-tip-text{
        width: 478/$r;
        margin: 64/$r 0 99/$r;
        font-size: 28/$r;
        font-weight: 500;
        color: #333333;
        line-height: 36/$r;
        span{
          color: #FF6B29;
        }
      }
      .overlay-btn{
        display: flex;
        margin-bottom: 68/$r;
        font-size: 28/$r;
        font-family: Source Han Sans CN;
        font-weight: 300;
        line-height: 74/$r;
        color: #999999;
        .cancel{
          width: 240/$r;
          height: 74/$r;
          margin-right: 42/$r;
          background: #FFFFFF;
          border: 1px solid #B5B5B5;
          border-radius: 37/$r;
          text-align: center;
        }
        .confirm{
          width: 240/$r;
          height: 74/$r;
          background: #FF6B29;
          border-radius: 37/$r;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
}
//转队不能做任务提示开始结束
</style>
